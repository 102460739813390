import { render, staticRenderFns } from "./turntableActivities.vue?vue&type=template&id=a6642c26&scoped=true"
import script from "./turntableActivities.vue?vue&type=script&lang=js"
export * from "./turntableActivities.vue?vue&type=script&lang=js"
import style0 from "./turntableActivities.vue?vue&type=style&index=0&id=a6642c26&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6642c26",
  null
  
)

export default component.exports