<template>
	<div class="editgiftdetails" v-loading="detailLoading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" style="margin-bottom:80px">
			<el-card class="box-card padding-left-0" >
				<div slot="header" class="clearfix">
					<span>基本信息</span>
				</div>
				<div class="content">
					<el-form-item label="活动名称：" prop="ActivityName">
						<el-input style="width:300px" placeholder="请输入活动名称，最多20个字" v-model.trim="ruleForm.ActivityName"
						 :disabled="IsDuring==3" maxlength="20"></el-input>
					</el-form-item>
					<el-form-item label="活动时间：" required>
						<el-form-item prop="StartTime" class="el-form-date-pick">
							<el-date-picker v-model="ruleForm.StartTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00"
							 type="datetime" placeholder="开始时间" :picker-options='maxOptions'  :disabled="IsDuring>1">
							</el-date-picker>
						</el-form-item>
						<span style="margin:0 10px;">~</span>
						<el-form-item prop="EndTime" class="el-form-date-pick">
							<el-date-picker v-model="ruleForm.EndTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
							 placeholder="结束时间" :picker-options='minOptions' default-time="23:59:59"  :disabled="IsDuring==3">
							</el-date-picker>
						</el-form-item>
					</el-form-item>
					<el-form-item label="活动限购：">
						<div>
							<el-radio v-model="ruleForm.radioLimit" label="0" :disabled="IsDuring==3" @change='noChangeLimit'>不限购</el-radio>
						</div>
						<div style="display: flex;align-items: center;">
							<el-radio v-model="ruleForm.radioLimit" label="1" @change='YesChangeLimit'  :disabled="IsDuring==3">
								限购
							</el-radio>
							<el-form-item :prop="ruleForm.radioLimit==1?'Quota':'nocheck'" :rules='ruleForm.radioLimit==1?rules.Quota:rules.nocheck'>
								<el-input :disabled="ruleForm.radioLimit==0||IsDuring==3" v-model="ruleForm.Quota" style="width: 100px;margin: 0 5px;"
								 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
								 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>件/人
							</el-form-item>
						</div>
						<div style="color: #909399;margin-top: 10px;">活动期间每个用户每种商品限购数量</div>
					</el-form-item>
					<el-form-item label="优惠券叠加：" required prop='IsSuperpositionCoupon'>
						<div>
							<el-radio v-model="ruleForm.IsSuperpositionCoupon" :disabled="IsDuring==3" :label="true">叠加</el-radio>
						</div>
						<div>
							<el-radio v-model="ruleForm.IsSuperpositionCoupon" :disabled="IsDuring==3" :label="false">不叠加</el-radio>
						</div>
						<div style="color: #909399;margin-top: 10px;">开启优惠券叠加后，本场活动商品可同时专享价、优惠券优惠</div>
					</el-form-item>
					<el-form-item label="活动标签：">
						<el-input style="width:300px" placeholder="未填写，默认为社群专享福利" v-model="ruleForm.ActivityFlag"
							 :disabled="IsDuring==3" maxlength="10"></el-input>
						<div class="grayFont" style="margin-top:5px;font-size:14px">活动期间显示在商品详情页、商品分享图片、商品列表
							<el-button type="text" style="margin:0px;padding:0px" @click="sampleShow=true">查看示例</el-button>
						</div>
					</el-form-item>
				</div>
			</el-card>
			<el-card style="margin-top:10px" class="padding-left-0">
				<div slot="header" class="clearfix">
					<span>社群专享商品</span>
				</div>
				<div style="width:1150px">
					<div class="flexRow" style="justify-content: space-between;">
						<el-form-item label="活动商品：" required prop="ProductSpecList" :inline-message="true">
							<!-- v-if='currentEditStatus=="未开始"' -->
							<el-button type="text" :class="{'is-error-button':!ruleForm.ProductSpecList.length}"  style="color:#409EFF;cursor: pointer;"
								@click="handlePrizePro"  :disabled="IsDuring==3">选择商品</el-button>
						</el-form-item>
						<el-input placeholder="搜索已选商品名称、编码" v-model="tablekeywords" style="width:300px" clearable>
							<el-button slot="append" icon="el-icon-search" @click="proPage.current=1,getpagelist()"></el-button>
						</el-input>
					</div>
					<div style="margin-left:120px">
						<!-- 抽奖活动表格 -->
						<el-table :data="ruleForm.pagelist" style="margin:0px 0px 50px 0px;" v-loading="loading" ref="multipleTable"
							:row-key="rowKeys" class="table-columnHeight-80">
							<el-table-column prop="" label="商品" min-width="150">
								<template slot-scope="scope">
									<div class="flexRow" style="width:100%">
										<img :src="scope.row.ImgUrlComplete" style="width:60px;height:60px;border-radius:3px" alt="">
										<div style="margin-left:5px;flex:1;width:0">
											<div class="ellipsis">
												<span>{{scope.row.Name}}</span>
											</div>
											<div style="color:#f56c6c">
												{{!scope.row.IsOpen?'已下架':(scope.row.Stock<=0?'已售罄':'')}}
											</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="showPrice" label="原价(元)" width="120"></el-table-column>
							<el-table-column prop="Stock" label="库存" width="120"></el-table-column>
							<el-table-column prop="" label="专享价(元)" width="180">
								<template slot-scope="scope">
									<el-form-item label-width="0" :prop="'pagelist.'+scope.$index+'.ExclusivePrice'" :rules="rules.ExclusivePrice"
									style="margin:20px 0px" :key="scope.row.Id" class="relativeErrMsg">
										<el-input style="width:130px" v-model="scope.row.ExclusivePrice" :placeholder="scope.row.placeholder"
										:disabled="IsDuring==3" @blur="blurset(scope.row)" @keyup.native='scope.row.ExclusivePrice=oninput($event,scope.row.ExclusivePrice)'></el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column prop="" label="操作">
								<template slot-scope="scope">
									<div>
										<el-button type="text" @click="setexclusive(scope.row)" v-if="scope.row.ProductSpecList.length>1" :disabled="IsDuring==3">设置规格专享价</el-button>
										<el-button type="text" style="color:#f56c6c" @click="pagelistDel(scope.row)" :disabled="IsDuring==3">删除</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
						<div style="margin-top:10px;text-align:right" v-if="proPage.total">
							<el-pagination
								@current-change="pagelistcurrentchange"
								:current-page="proPage.current"
								:page-size="proPage.size"
								layout="total, prev, pager, next, jumper"
								:total="proPage.total">
							</el-pagination>
						</div>
					</div>
				</div>
			</el-card>
			<el-card style="margin-top:10px" class="padding-left-0">
				<div slot="header" class="clearfix">
					<span>关联群聊</span>
				</div>
				<div class="blueBox">
					<div>
						<span>1.请前往企业微信后台—客户群中下载群聊二维码，并上传在活动配置页。客户进入商详页，将引导加入活动关联的群聊</span>
						<el-link href="https://jusnn6k8al.feishu.cn/docx/doxcnyyMiwTpw98mleiaWfPLp2c"
						 target="_blank" type="primary" :underline="false" style="margin-bottom:2px;margin-left:5px">查看说明</el-link>
					</div>
					<div>
						2.上传的群聊二维码需要和关联群聊保持一致，否则客户扫码加入错误群聊，无法享受社群专享活动价
					<div style="color:#999999">示例：活动关联群聊是A群（即客户进入A群后可享受活动价），上传的群二维码是B群，客户扫码进入B群后，无法享受活动价</div>
					</div>
					<div>
						3.每个群，扫码进群人数上限200人，客户进入商详页点击加入社群，不会推送已达上限人数的群聊二维码
					</div>
					<div>
						4.群二维码7天内有效，请关注并及时更新群二维码。二维码过期，加群失败
					</div>
				</div>
				<el-form-item :label="'群聊'+(i+1)+'：'" prop="" required style="margin-top:20px"
				 v-for="(v,i) in ruleForm.ActivityCommunityGroupChoseGroupList" :key="i">
					<div>
						<div class="flexEnd">
							<div class="groupBox">
								<el-form-item label="关联群聊：" :prop="'ActivityCommunityGroupChoseGroupList.'+i+'.QyWeixinRealGroupId'" :rules="rules.QyWeixinRealGroupId"
								 class="havestar" label-width="100px">
									<el-select v-model="v.QyWeixinRealGroupId" :disabled="IsDuring==3" filterable placeholder="输入群聊名称，选择群聊"
									style="width:300px;" class="selgroup" popper-class="selpopclass">
										<el-option v-for="(x,y) in groutChatList" :key="y" :value="x.Id" :label="x.GroupName"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="群二维码：" required :prop="'ActivityCommunityGroupChoseGroupList.'+i+'.GroupQrCode'" :rules="rules.GroupQrCode"
								 label-width="100px" style="margin-top:20px">
									<el-upload ref="uploader" :action="imgApi" accept="image/*" :disabled="IsDuring==3" :key="'upload'+i"
										:on-success="(e)=>{imgOnSuccess(e,i)}" class="qrCodeBox-10"
										:before-upload="beforeUpload"
										:show-file-list="false" list-type="picture-card">
										<div v-if="v.GroupQrCode" class="EditassociateData-uploadImg" @click.stop="{}">
											<img :src="imgUrl+v.GroupQrCode" alt="">
											<div class="mask">
												<span>重新选择</span>
												<el-upload ref="" :key="'upload2'+i" class="again" :action="imgApi" accept="image/*" :disabled="IsDuring==3"
													:on-success="(e)=>{imgOnSuccess(e,i)}"
													:before-upload="beforeUpload"
													:show-file-list="false" list-type="picture-card">
												</el-upload>
											</div>
										</div>
										<i class="el-icon-plus" v-else></i>
									</el-upload>
								</el-form-item>
							</div>
							<el-button type="text" style="color:#f56c6c;margin:0px 0px 10px 20px" @click="delgroup(i)"	:disabled="IsDuring==3"
							 v-if="ruleForm.ActivityCommunityGroupChoseGroupList.length>1">删除群聊</el-button>
						</div>
					</div>
				</el-form-item>
				<el-button type="primary" style="margin-left:120px" plain @click="pushgroup"	:disabled="IsDuring==3"
				 v-if="ruleForm.ActivityCommunityGroupChoseGroupList.length<10">添加群聊</el-button>
			</el-card>
		</el-form>

		<!-- 选择商品-->
		<el-dialog title="选择商品" :visible.sync="selectProShow" width="1100px" class="dialog data-dialog" @close="close">
			<select-produce :selectProShow="selectProShow" :proCheckedId='proCheckedId' :couponData="couponData" :defaultlist="ruleForm.ProductSpecList"
			 @getSelection='getSelectPros' ref="selectproduce"></select-produce>
		</el-dialog>
		<!-- 保存商品冲突 -->
		<el-dialog title="以下商品与其他活动冲突，请重新设置" :visible.sync="conflictGoodsShow" width="1000px" custom-class="dialog-body-topLine" class="dialog data-dialog">
			<div>
				<el-table border :data="conflictGoodsList" style="max-height:450px;overflow: auto;">
					<el-table-column :key="20" label="商品" width="300px">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<img :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
								<div class="right">
									<div class="name">
										{{scope.row.ProductName}}
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :key="21" prop="Remark" label="冲突信息"></el-table-column>
				</el-table>
			</div>
			<div style="text-align:center;margin-top:20px;">
				<el-button class="button" style="width:200px" @click="conflictGoodsShow=false" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="设置规格专享价" :visible.sync="setShow" width="800px" @close="closeSet" custom-class="dialog-body-paddingTop-10">
			<div slot="title">设置规格专享价</div>
			<el-form :model="rowMsg" :rules="rowrules" ref="rowMsg">
				<div class="grayFont" style="font-size:14px;color:#606266">商品：{{rowMsg.Name}}</div>
				<el-table :data="rowMsg.rowtablelist" style="margin-top:20px" max-height="400" border class="table-columnHeight-80">
					<el-table-column prop="" label="规格">
						<template slot-scope="scope">
							<div>
								<div>{{scope.row.SpecValue}}
									<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
								</div>
								<div class="grayFont">{{scope.row.Barcode}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Price" label="规格原价（元）"></el-table-column>
					<el-table-column prop="" label="规格专享价（元）">
						<template slot-scope="scope">
							<el-form-item class="relativeErrMsg" label-width="0" :prop="'rowtablelist.'+scope.$index+'.ExclusivePrice'" :rules="rowrules.ExclusivePrice"
							 style="margin:20px 0px" :key="scope.row.ProductSpecId">
								<el-input style="width:130px;height:32px" size="small" v-model="scope.row.ExclusivePrice" @blur="rowblurset(scope.row)"
									 @keyup.native='scope.row.ExclusivePrice=oninput($event,scope.row.ExclusivePrice)'></el-input>
							</el-form-item>
						</template>
					</el-table-column>
				</el-table>
				<div class="lastcolumn flexRow" v-if="rowMsg.rowtablelist&&rowMsg.rowtablelist.length">
					批量设置：
					<el-button type="text" v-if="!setprice" @click="setprice = true">专享价</el-button>
					<div class="flexRow" v-else>
						<el-input style="width:150px" v-model.trim="premiumprice" placeholder="" 
						 @keyup.native='premiumprice=oninput($event,premiumprice)'></el-input>
						<el-button type="text" style="margin-left:10px" @click="saveprice">保存</el-button>
						<el-button type="text" style="color:#606266" @click="cancelprice">取消</el-button>
					</div>
				</div>
				<div style="margin-top:10px;text-align:right" v-if="rowtotal">
					<el-pagination
						@current-change="rowhandleCurrentChange"
						:current-page="rowcurrentPage"
						:page-size="rowpagesize"
						layout="total, prev, pager, next, jumper"
						:total="rowtotal">
					</el-pagination>
				</div>
				<div style="margin-top:10px;text-align:right">
					<el-button @click="closeSet">取消</el-button>
					<el-button type="primary" @click="savelist">保存</el-button>
				</div>
			</el-form>
		</el-dialog>

		<el-dialog title="查看示例" :visible.sync="sampleShow" width="600px">
			<div class="flexStartCenter">
				<div class="flexRow" style="flex-direction: column;margin-right:50px">
					<div>商品详情页</div>
					<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/EditassociateData1.png" style="width:220px;margin-top:10px" alt="">
				</div>
				<div class="flexRow" style="flex-direction: column;">
					<div>商品分享图片</div>
					<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/EditassociateData2.png" style="width:220px;margin-top:10px" alt="">
				</div>
			</div>
		</el-dialog>

		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselEdit">取消</el-button>
			<el-button type="primary" style="width:240px" v-if="IsDuring!=3" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		activityCommunityGroupInfo,
		activityCommunityGroupSave,
		qyweixinrealgrouppagelist
	} from '@/api/TurnTomySelf.js'
	
	// import selectProduce from '@/components/SelectSingleProduce/lottery'
	import selectProduce from './components/turntableActivities'
	export default {
		components: {
			selectProduce
			// giftdataProduct,
			// turntableActivities,
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var limitNumberCheck = (rule, value, callback) => {
				console.log(value, 789456)
				if (!value && value !== 0) {
					return callback(new Error('请输入限购数量'));
				} else if (value < 0 || value > 99999) {
					return callback(new Error('限购数量请设置在0~99999之间'));
				} else {
					return callback();
				}
			};
			var checkExclusivePrice = (rule, value, callback) => {
				let index = rule.field.substring(
					'pagelist'.length+1,rule.field.length-'ExclusivePrice'.length-1
				)
				let row = this.ruleForm.pagelist[index]
				let two = /^\d+(\.\d{1,2})?$/
				// console.log(row)
				if(row.Isequal){
					if(!row.ExclusivePrice){
						callback(new Error('请设置专享价'))
					}else if(row.ExclusivePrice < 0.01){
						callback(new Error('专享价最小0.01元'))
					}else if(row.ExclusivePrice>row.minprice){
						callback(new Error('专享价不可高于原价'))
					}else if(!two.test(row.ExclusivePrice)){
						callback(new Error('请输入正确的价格'))
					}else{
						callback()
					}
				}else{
					if(!row.placeholder){
						callback(new Error('请设置专享价'))
					}else{
						callback()
					}
				}
				
			};
			var checkRowExclusivePrice = (rule, value, callback) => {
				let index = rule.field.substring(
					'rowtablelist'.length+1,rule.field.length-'ExclusivePrice'.length-1
				)
				let row = this.rowMsg.rowtablelist[index]
				let two = /^\d+(\.\d{1,2})?$/
				console.log(row,value)
				if(!row.ExclusivePrice){
						callback(new Error('请设置专享价'))
					}else if(row.ExclusivePrice < 0.01){
					callback(new Error('专享价最小0.01元'))
				}else if(row.ExclusivePrice>row.Price){
					callback(new Error('专享价不可高于原价'))
				}else if(!two.test(row.ExclusivePrice)){
					callback(new Error('请输入正确的价格'))
				}else{
					callback()
				}
			};
			var checkQyWeixinRealGroupIdList = (rule, value, callback) => {
				console.log(value)
				if(!value||!value.length){
					callback(new Error('请选择群聊'))
				}else if(value.length>5){
					callback(new Error('最多可选择5个群聊'))
				}else{
					callback()
				}
			};
			return {
				imgApi: config.UPLOAD_IMG,
				conflictGoodsList: [],
				conflictGoodsShow: false,
				imgUrl: config.IMG_BASE,
				loading: false,
				couponData: {},
				detailLoading: false,
				ruleForm: {
					Id:0,
					ActivityName:'',
					StartTime: '',
					EndTime: '',
					radioLimit: '0',
					Quota: '',
					ProductSpecList: [],
					IsSuperpositionCoupon: null,
					ProductType: '',
					ProductName: '',
					ImgUrl: '',
					// Delstatus:'',
					ActivityFlag:'',
					QyWeixinRealGroupIdList:[],
					GroupQrCode:'',
					ActivityCommunityGroupChoseGroupList:[{
						QyWeixinRealGroupId:'',
						GroupQrCode:''
					}],

					pagelist:[]
				},
				rules: {
					ActivityName: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
					Quota: [{
						required: true,
						validator: limitNumberCheck,
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					IsSuperpositionCoupon: [{
						required: true,
						message: '请配置优惠券叠加方式',
						trigger: 'change'
					}],
					QyWeixinRealGroupId: [{ 
							required: true,
							message: '请选择群聊',
							trigger: 'blur'
					}],
					GroupQrCode: [{
						required: true,
						message: '请上传群聊二维码',
						trigger: 'blur'
					}],
					ExclusivePrice:[
						{ validator: checkExclusivePrice, trigger: 'blur' }
					],
					ProductSpecList:[{
						required: true,
						message: '请选择活动商品',
						trigger: 'blur'
					}],
				},
				proPage: {
					current: 1,
					total: 0,
					size: 5
				},
				selectProShow: false,
				// checkShowId:0,
				proCheckedId: 0,
				selectProductlist: {}, //选中的活动商品和规格
				currentEditStatus: null,
				currentEditType: null,
				saveLoading: false,

				tablekeywords:'',
				groutChatList:[],

				rowMsg:{},
				rowrules:{
					ExclusivePrice:[
						{ validator: checkRowExclusivePrice, trigger: 'blur' }
					]
				},
				setShow:false,
				setprice:false,
				premiumprice:'',
				rowtotal:0,
				rowpagesize:5,
				rowcurrentPage:1,
				rowtablelist:[],

				ProductSpecList:[],

				IsDuring:1,
				sampleShow:false,
			}
		},
		created() {
			this.currentEditStatus = this.$route.query.status
			this.currentEditType = this.$route.query.type
			//获取详情
			this.getinfo()
			
		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		methods: {
			delgroup(e){
				this.ruleForm.ActivityCommunityGroupChoseGroupList.splice(e,1)
			},
			pushgroup(){
				this.ruleForm.ActivityCommunityGroupChoseGroupList.push({
					QyWeixinRealGroupId:'',
					GroupQrCode:''
				})
			},
			close(){
				this.$refs.selectproduce.close()
			},
			oninput (e) {
				e.target.value = e.target.value.replace(/[^\d.]/g, '')
				e.target.value = e.target.value.replace(/\.{2,}/g, '.')
				e.target.value = e.target.value.replace(/^\./g, '0.')
				e.target.value = e.target.value.replace(/^\d*\.\d*\./g, e.target.value.substring(0, e.target.value.length - 1))
				e.target.value = e.target.value.replace(/^0[^\.]+/g, '0')
				e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2')
				return e.target.value
			},
			async getinfo(){
				await this.getqyweixinrealgrouppagelist()
				if (this.$route.query.Id > 0) {
					this.getInformation()
				}
			},
			blurset(row){
				// console.log(row,555)
				if(!row.ExclusivePrice && !row.Isequal){
					return
				}
				this.ProductSpecList = this.ProductSpecList.map(v=>{
					if(v.Id==row.Id){
						v = row
						v.Isequal = true
						v.placeholder = ''
						v.ProductSpecList = v.ProductSpecList.map(x=>{
							x.ExclusivePrice = row.ExclusivePrice
							return x
						})
					}
					return v
				})
				// this.ProductSpecList = this.ProductSpecList.map(v=>{
				// 	if(v.Id==row.Id){
				// 		v.ProductSpecList = v.ProductSpecList.map(x=>{
				// 			x.ExclusivePrice = row.ExclusivePrice
				// 			return x
				// 		})
				// 	}
				// 	return v
				// })
				this.ruleForm.ProductSpecList = this.ProductSpecList
				// console.log(this.ruleForm.ProductSpecList,this.ProductSpecList,123,this.ruleForm.pagelist)
			},
			rowblurset(row){
				if(!row.ExclusivePrice){
					return
				}
				this.rowMsg.ProductSpecList = this.rowMsg.ProductSpecList.map(v=>{
					if(v.ProductSpecId==row.ProductSpecId){
						v=row
					}
					return v
				})
				// console.log(this.rowMsg.ProductSpecList)
			},
			setexclusive(row){
				this.rowMsg = JSON.parse(JSON.stringify(row))
				this.rowcurrentPage = 1
				// console.log(this.rowMsg,1)
				this.gettablelist()
				this.setShow = true
			},
			gettablelist(){
				this.rowtotal = this.rowMsg.ProductSpecList.length
				this.rowMsg.rowtablelist = this.rowMsg.ProductSpecList.filter((v,i)=>{
					return i>=(this.rowcurrentPage-1)*this.rowpagesize&&i<this.rowcurrentPage*this.rowpagesize
				})
				if((!this.rowMsg.rowtablelist||!this.rowMsg.rowtablelist.length)&&this.rowcurrentPage>1){
					this.rowcurrentPage--
					this.gettablelist()
					return 
				}
				this.$forceUpdate()
				// console.log(this.rowMsg.rowtablelist,2)
			},
			pagelistDel(row){
				this.ProductSpecList = this.ProductSpecList.filter(v=>{
					return v.Id!=row.Id
				})
				this.ruleForm.ProductSpecList = this.ProductSpecList
				this.getpagelist()
			},
			pagelistcurrentchange(e){
				this.proPage.current = e
				this.getpagelist()
			},
			savelist(){
				this.$refs.rowMsg.validate((valid) => {
          if (valid) {
						let two = /^\d+(\.\d{1,2})?$/
						let stop = false
						for(let index in this.rowMsg.ProductSpecList){
							let item = this.rowMsg.ProductSpecList[index]
							if(item.ExclusivePrice<0.01||item.ExclusivePrice>item.Price||!two.test(item.ExclusivePrice)){
								this.jumpcurrent(1,index)
								stop = true
								return 
							}
						}
						if(stop){
							return 
						}
						this.ProductSpecList = this.ProductSpecList.map(v=>{
							if(v.Id==this.rowMsg.Id){
								v = this.rowMsg
								let exprice = v.ProductSpecList.map(x=>x.ExclusivePrice)
								v.minexprice = Math.min(...exprice)
								v.maxexprice = Math.max(...exprice)
								// console.log(v.minexprice,v.maxexprice)
								if(v.minexprice==v.maxexprice){
									v.Isequal = true
									v.placeholder = ''
									v.ExclusivePrice = v.minexprice
								}else{
									v.Isequal = false
									v.placeholder = v.minexprice + '~' + v.maxexprice
									v.ExclusivePrice = ''
								}
							}
							// console.log(v)
							return v
						})
						this.ruleForm.ProductSpecList = this.ProductSpecList
						this.getpagelist()
						this.closeSet()
          } else {
						this.$message.error('存在专享价设置错误的规格数据，请检查并修改')
            return false;
          }
        })
			},
			jumpcurrent(num,index){
				// console.log('stop')
				if(num==1){
					this.rowcurrentPage = ((index+1)%this.rowpagesize)+1
					this.gettablelist()
					this.$message.error('存在专享价设置错误的规格数据，请检查并修改')
					this.$nextTick(()=>{
						this.$refs.rowMsg.validate()
					})
				}else if(num==2){
					this.proPage.current = ((index+1)%this.proPage.size)+1
					console.log(this.proPage.current,123)
					this.getpagelist()
					this.$message.error('请完善活动配置')
					this.$nextTick(()=>{
						this.$refs.ruleForm.validate()
					})
				}
			},
			rowhandleCurrentChange(e){
				this.rowcurrentPage = e
				this.gettablelist()
			},
			closeSet(){
				this.setShow = false
				this.rowMsg = {}
				this.cancelprice()
			},
			saveprice(){
				if(this.premiumprice){
					this.rowMsg.ProductSpecList = this.rowMsg.ProductSpecList.map(v=>{
						v.ExclusivePrice = this.premiumprice
						return v
					})
				}
				this.setprice = false
			},
			cancelprice(){
				this.setprice = false
				this.premiumprice = ''
			},
			imgOnSuccess(file,i){
				// console.log(file)
				this.ruleForm.ActivityCommunityGroupChoseGroupList[i].GroupQrCode = file[0]
			},
			beforeUpload(file){
				// console.log(file)
				const size = file.size / 1024 / 1024 < 1;
				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('图片大小控制在1M以内');
					return false
				}
			},
			async getqyweixinrealgrouppagelist(){
				let data = {
					IsDissolution: false,
					IsQyWeixinRealGroupSelect: true,
					Skip: 0,
					Take: 99999,
				}
				let result = await qyweixinrealgrouppagelist(data)
				if (result.IsSuccess) {
					// console.log(result.Result.Results,'群聊')
					this.groutChatList = result.Result.Results
				}
			},
			noChangeLimit() {
				this.$forceUpdate()
				this.ruleForm.radioLimit = this.ruleForm.radioLimit
			},
			YesChangeLimit() {
				this.$forceUpdate()
				this.ruleForm.radioLimit = this.ruleForm.radioLimit
			},
			// 保存
			submitForm(formName) {
							// console.log(this.ruleForm.ProductSpecList,this.ProductSpecList)
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.saveLoading = true
							if(this.ruleForm.StartTime == this.ruleForm.EndTime){
								this.$message({
									showClose: true,
									type: 'error',
									message: '请设置正确的时间'
								})
								return
							}
							this.ruleForm.ProductSpecList = this.ProductSpecList
							if (!this.ruleForm.ProductSpecList.length) {
								this.$common.completeFormInformation('商品至少选择一件','is-error-button')
								return
							}
							let two = /^\d+(\.\d{1,2})?$/
							let stop = false
							for(let index in this.ruleForm.ProductSpecList){
								let item = this.ruleForm.ProductSpecList[index]
								// console.log(item.ExclusivePrice,index)
								if(item.Isequal){
									if(item.ExclusivePrice < 0.01||item.ExclusivePrice>item.maxprice||!two.test(item.ExclusivePrice)){
										this.stop = true
										this.jumpcurrent(2,index)
										return
									}
								}else{
									if(!item.placeholder){
										this.stop = true
										this.jumpcurrent(2,index)
										return
									}
								}
							}
							if(stop){
								return
							}
							// let ExclusivePrices = this.ruleForm.ProductSpecList.filter(item => {
							// 	return String(item.ExclusivePrice) == ''
							// })
							// if (ExclusivePrices.length) {
							// 	this.$message({
							// 		showClose: true,
							// 		type: 'error',
							// 		message: '请设置专享价'
							// 	})
							// 	return
							// }
							// console.log(this.ruleForm.ProductSpecList)
							// return
							let data = {
								// StartTime: this.ruleForm.StartTime,
								// EndTime: this.ruleForm.EndTime,
								// Quota: this.ruleForm.radioLimit == 1 ? this.ruleForm.Quota : 0,
								// IsSuperpositionCoupon: this.ruleForm.IsSuperpositionCoupon,
								// ProductSpecList: this.ruleForm.ProductSpecList,
								...this.ruleForm,
								noError:true
							}
							data.Quota = data.radioLimit == 1? data.Quota : 0
							if (this.$route.query.type == 1 && this.$route.query.Id > 0) {
								data.Id = this.$route.query.Id
							} else {
								data.Id = 0
							}
							let list = []
							data.ProductSpecList.map(v=>{
								v.ProductSpecList.map(x=>{
									list.push(x)
								})
							})
							data.ProductSpecList = list
							let result = await activityCommunityGroupSave(data)
							// console.log(result)
							if(result.IsSuccess){
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.$router.push({
									path: '/market/association/associateShare'
								});
							}else if(result.Result && result.Result.length) {
								// this.$message({
								// 	showClose: true,
								// 	type: 'error',
								// 	message: '操作失败'
								// });
								this.conflictGoodsList = result.Result
								this.conflictGoodsShow = true
							} else {
								this.$message.error(result.Message)
							}


						} catch (e) {
							//TODO handle the exception
							console.log(e)
						} finally {

							this.saveLoading = false
						}


					} else {
						
						this.$common.completeFormInformation('请完善活动信息','is-error')
						console.log('error submit!!');
						return false;
					}
				});

			},
			// 取消
			canselEdit() {
				this.$router.push({
					path: '/market/association/associateShare'
				});
			},
			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			rowKeys(row) {
				return row.Id
			},
			handlePrizePro() {
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' || this.ruleForm
						.EndTime == null)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					// 添加复制是2  (编辑)
					this.couponData.id = this.$route.query.type == 2 ? 0 : this.ruleForm.Id
					// this.couponData.id = this.ruleForm.Id ? this.ruleForm.Id : 0
					this.couponData.StartTime = this.ruleForm.StartTime
					this.couponData.EndTime = this.ruleForm.EndTime
					this.selectProShow = true
					this.$nextTick(()=>{
						// this.$refs.selectproduce.getSingleList()
						// this.$refs.selectproduce.init()
					})
				// console.log(this.ProductSpecList,this.ruleForm.ProductSpecList,this.ruleForm.pagelist)
				}
			},
			getSelectPros(list) {
				if(!list||!list.length){
					this.$message.error('请选择商品')
					return
				}
				// console.log(this.ProductSpecList,this.ruleForm.ProductSpecList,this.ruleForm.pagelist)

				this.ProductSpecList = list.map(v=>{
					let pricelist = v.ProductSpecList.map(x=>x.Price)
					v.minprice = Math.min(...pricelist)
					v.maxprice = Math.max(...pricelist)
					if(v.minprice==v.maxprice){
						v.showPrice = v.minprice
					}else{
						v.showPrice = v.minprice + '~' + v.maxprice
					}



					// v.Isequal = true
					// v.placeholder = ''
					// v.ExclusivePrice = ''

					this.ruleForm.ProductSpecList.map(x=>{
						if(x.Id==v.Id){
							// console.log(x)
							v.Isequal = x.Isequal
							v.placeholder = x.placeholder
							v.ExclusivePrice = x.ExclusivePrice
							v.ProductSpecList = x.ProductSpecList
						}
					})
					// console.log(v.ExclusivePrice)
					// this.$forceUpdate()
					return v
				})
				this.ruleForm.ProductSpecList = this.ProductSpecList
				this.selectProShow = false
				this.getpagelist()
				// console.log("Pro selection",selection)
			},
			// 初始化数据
			async getInformation() {
				try {
					this.detailLoading = true;
					let data = {
						Id: this.$route.query.Id,
						IsCopy: this.$route.query.type == 2 && this.$route.query.Id ? true : false
					}
					let result = await activityCommunityGroupInfo(data)
					let ruleForm = result.Result
					
					ruleForm.ProductSpecList = ruleForm.ProductToCommunityGroupList.map(v=>{
						v.Id == v.ProductId

						let pricelist = v.ProductSpecList.map(x=>x.Price)
						v.minprice = Math.min(...pricelist)
						v.maxprice = Math.max(...pricelist)
						if(v.minprice==v.maxprice){
							v.showPrice = v.minprice
						}else{
							v.showPrice = v.minprice + '~' + v.maxprice
						}

						return v
					})

					let chatlist = this.groutChatList.map(v=>v.Id)
					ruleForm.ActivityCommunityGroupChoseGroupList = ruleForm.ActivityCommunityGroupChoseGroupList.map(v=>{
						if(chatlist.indexOf(v.QyWeixinRealGroupId)==-1){
							v.QyWeixinRealGroupId = ''
						}
						return v
					})
					if (ruleForm.Quota > 0) {
						ruleForm.radioLimit = '1'
					} else {
						ruleForm.radioLimit = '0'
					}
					this.ruleForm = await this.changeshowprice(ruleForm)
					this.getpagelist()
					
					// console.log(this.ruleForm)
					//复制
					if (this.$route.query.type == 2) {
						this.ruleForm.StartTime = ''
						this.ruleForm.EndTime = ''
						this.ruleForm.Id = 0
						this.ruleForm.ActivityCommunityGroupChoseGroupList = [{
							QyWeixinRealGroupId:'',
							GroupQrCode:''
						}]
						return
					}
					this.getdisabled()
				} catch (e) {
					console.log(e)
				} finally {
					this.detailLoading = false
				}

			},
			getpagelist(){
				// console.log(this.ProductSpecList,this.ProductSpecList.length)
				// console.log(this.ProductSpecList,2)
				let keylist = JSON.parse(JSON.stringify(this.ProductSpecList)).filter((v,i)=>{
					if(this.tablekeywords){
						return v.Name.indexOf(this.tablekeywords)>-1||v.ProductNo.indexOf(this.tablekeywords)>-1
					}else{
						return v
					}
				})
				this.proPage.total = keylist.length
				// console.log(keylist)
				this.ruleForm.pagelist = keylist.filter((v,i)=>{
					return i>=(this.proPage.current-1)*this.proPage.size&&i<this.proPage.current*this.proPage.size
				})
				// console.log(this.ruleForm.pagelist,111)
				if((!this.ruleForm.pagelist||!this.ruleForm.pagelist.length)&&this.proPage.current>1){
					this.proPage.current--
					this.getpagelist()
					return
				}
				this.$forceUpdate()
				
			},
			async changeshowprice(list){
				let ruleForm = list

				ruleForm.ProductSpecList = ruleForm.ProductSpecList.map(v=>{
					
					//原价
					let pricelist = v.ProductSpecList.map(x=>x.Price)
					v.minprice = Math.min(...pricelist)
					v.maxprice = Math.max(...pricelist)
					if(v.minprice==v.maxprice){
						v.showPrice = v.minprice
					}else{
						v.showPrice = v.minprice + '~' + v.maxprice
					}
					//专享价
					let exprice = v.ProductSpecList.map(x=>x.ExclusivePrice)
					v.minexprice = Math.min(...exprice)
					v.maxexprice = Math.max(...exprice)
					if(v.minexprice==v.maxexprice){
						v.Isequal = true
						v.placeholder = ''
						v.ExclusivePrice = v.minexprice
					}else{
						v.Isequal = false
						v.placeholder = v.minexprice + '~' + v.maxexprice
						v.ExclusivePrice = ''
					}
					if(this.$route.query.type == 2){
						v.Isequal = false
						v.placeholder = ''
						v.ExclusivePrice = ''
						v.ProductSpecList = v.ProductSpecList.map(x=>{
							x.ExclusivePrice = ''
							return x
						})
					}
					return v
				})
				this.ProductSpecList = ruleForm.ProductSpecList
				// console.log(this.ProductSpecList)
				return ruleForm
			},
			getdisabled(){
				let date = new Date().getTime()
				let start = new Date(this.ruleForm.StartTime).getTime()
				let end = new Date(this.ruleForm.EndTime).getTime()
				if(date<start){
					this.IsDuring = 1
				}else if(date>=start&&date<end){
					this.IsDuring = 2
				}else if(date>=end){
					this.IsDuring = 3
				}
				if(!this.ruleForm.IsOpen){
					this.IsDuring = 3
				}
			}
		}
	}
</script>

<style lang="less" scoped>
.flexStartCenter{
	display: flex;
	align-items: flex-start;
	justify-content: center;
}
	.ellipsis {
		overflow     : hidden;
		text-overflow: ellipsis;
		white-space  : nowrap;
		
	}
	.lastcolumn{
		min-height: 60px;
		border:1px solid rgb(235,238,245);
		border-top: none;
		padding: 0px 20px;
	}
	.grayFont{
		font-size: 12px;
		color:#999999;
		line-height: 1.5;
	}
	.flexRow{
		display: flex;
		align-items: center;
		
	}
	.blueBox{
		border:1px solid #409eff;
		padding: 15px 80px 15px 15px;
		margin: 0px 20px;
		display: inline-block;
		div{
			font-size:14px;
			line-height: 1.5;
			color:#606266;
			margin-bottom: 3px;
		}
	}
	.groupBox{
		border: 1px solid #eeeeee;
		padding: 20px 80px 20px 20px;
		background: #fbfbfb;
		flex-shrink: 0;
		display: inline-block;
		// margin-bottom:20px;
	}
	.flexEnd{
		display: flex;
		align-items: flex-end;
		// border:1px solid black
	}
	.editgiftdetails {
		padding: 10px;
		background: #fff;

		.el-form-item__error {
			width: 180px !important;
		}

		.el-form-date-pick {
			display: inline-block;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.lookImgsWidth {
			width: 460px;
		}

		.dialog-name-content {
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}

			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			span {
				color: #E51C23;
				font-size: 12px;
			}
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		// .number__input {
		// 	border: 1px solid #ccc;
		// }


	}
</style>

<style lang="less">

.selgroup{
	// border:1px solid black;
	.el-tag{
		overflow     : hidden;
		text-overflow: ellipsis;
		white-space  : nowrap;
		display: flex;
		align-items: center;
		.el-select__tags-text{
			flex:1;
			overflow     : hidden;
			text-overflow: ellipsis;
			white-space  : nowrap;
		}
	}
}
.selpopclass{
	max-width: 300px;
}
	.havestar .el-form-item__label::before{
		content: '*';
		color:#f56c6c;
		margin-right:4px
	}
.EditassociateData-uploadImg{
	width:100%;
	height:100%;
	// z-index: 50;
	position: relative;
	cursor: default;
	img{
		width: 100%;
		height: 100%;
		border-radius: 4px;
	}
	.mask{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 30px;
		background: rgba(0, 0, 0, .5);
		opacity: 0;
		cursor: pointer;
		// border:1px solid red;
		color: white;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height:1.5;
		.again{
			width: 100%;
			height: 100%;
			// border:1px solid red;
			position: inherit;
			.el-upload{
				// border:1px solid red;
				position: relative;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}
	&:hover .mask{
		opacity: 1;
	}
}
</style>
